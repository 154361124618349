import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Spinner,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Alert,
  Row,
  Col,
} from "reactstrap";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  createQuotation,
  updateQuotation,
} from "../../redux/actions/quotation";
import { useLocation, useHistory } from "react-router-dom";
import Loading from "../../components/Loading/Loading";

function CreateQuotation() {
  const dispatch = useDispatch();
  const currentLang = useSelector((state) => state.lang.lang);
  const response = useSelector((state) => state.quotation.create);
  const responseUpdate = useSelector((state) => state.quotation.update);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [pdf, setPdf] = useState();
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [sirs, setSirs] = useState("");
  const [rut, setRut] = useState("");
  const [phone, setPhone] = useState("");
  const [obs, setObs] = useState("");
  const [price, setPrice] = useState("");
  const [iva, setIva] = useState("");
  const [comission, setComission] = useState("");
  const [name, setName] = useState("");
  const [version, setVersion] = useState(1);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const location = useLocation();
  let history = useHistory();
  const [update, setupdate] = useState(false);

  useEffect(() => {
    if (response && loading && update) {
      console.log(response);
      if (response.message === "Quotation created") {
        setAlertTitle(response.message);
        window.open(response.data);

        setLoading(false);
        setShowAlert(true);
        goToQuotationList();
      }
    }
    if (responseUpdate && loading && update) {
      console.log(responseUpdate);
      if (responseUpdate.message === "Quotation updated") {
        setAlertTitle(responseUpdate.message);
        window.open(responseUpdate.data);

        setLoading(false);
        setShowAlert(true);
        goToQuotationList();
      }
    }
  }, [response, responseUpdate]);

  const goToQuotationList = () => {
    history.push({
      pathname: "/quotationlist",
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "BGL - Crear Cotizacion";
  }, []);

  useEffect(() => {
    if (location.state === undefined) {
      return;
    } else if (update === false) {
      let data = location.state.data;
      setPrice(data.price);
      setIva(data.iva);
      setComission(data.comission);
      if (data.pdf) {
        setData(data.numerointerno);
        setEmail(data.email);
        setContact(data.contact);
        setSirs(data.sirs);
        setRut(data.rut);
        setPhone(data.phone);
        setObs(data.obs);
        setPrice(data.price);
        setIva(data.iva);
        setComission(data.comission);
        setPdf(data.pdf);
        setVersion(data.version);
      } else {
        setData(data.numerointerno);
      }
      setLoading(false);
      setupdate(true);
    }
  });

  const onDismiss = () => setShowAlert(false);

  const onFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setName(data + " " + sirs);
    if (pdf) {
      console.log("update");
      dispatch(
        updateQuotation(
          data,
          sirs,
          rut,
          contact,
          email,
          phone,
          obs,
          price,
          iva,
          comission,
          name,
          pdf,
          version
        )
      );
    } else {
      console.log("create");
      dispatch(
        createQuotation(
          data,
          sirs,
          rut,
          contact,
          email,
          phone,
          obs,
          price,
          iva,
          comission,
          name,
          version
        )
      );
    }
  };

  function numberWithDot(x) {
    let n = x.split(".").join("");
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const onChangeValue = (value) => {
    let n = numberWithDot(value);
    setPrice(n);
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <Container>
        <Alert isOpen={showAlert} toggle={onDismiss}>
          {alertTitle} - {alertMessage}
        </Alert>
      </Container>
      <h3 style={{ textAlign: "center" }}>
        NUMERO INTERNO: {location.state.data.numerointerno}
      </h3>
      <Container className="p-3 container-border">
        <Form onSubmit={(e) => onFormSubmit(e)}>
          <Row>
            <Col lg="4"></Col>
            <Col sm="12" lg="12">
              <FormGroup>
                <Label for="sirs">Señores</Label>
                <Input
                  type="tex"
                  placeholder="Señores..."
                  disabled={loading}
                  onChange={(e) => setSirs(e.target?.value)}
                  value={sirs}
                />
              </FormGroup>
              <FormGroup>
                <Label for="rut">RUT</Label>
                <Input
                  type="text"
                  placeholder="RUT..."
                  disabled={loading}
                  onChange={(e) => setRut(e.target?.value)}
                  value={rut}
                />
              </FormGroup>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="text"
                  placeholder="Email..."
                  disabled={loading}
                  onChange={(e) => setEmail(e.target?.value)}
                  value={email}
                />
              </FormGroup>
              <FormGroup>
                <Label for="contact">Empresa</Label>
                <Input
                  type="text"
                  placeholder="EMPRESA..."
                  disabled={loading}
                  onChange={(e) => setContact(e.target?.value)}
                  value={contact}
                />
              </FormGroup>
              <FormGroup>
                <Label for="phone">Telefono</Label>
                <Input
                  type="text"
                  placeholder="Telefono..."
                  disabled={loading}
                  onChange={(e) => setPhone(e.target?.value)}
                  value={phone}
                />
              </FormGroup>
              <FormGroup>
                <Label for="price">Valor</Label>
                <Input
                  type="text"
                  placeholder="Valor..."
                  disabled={loading}
                  value={price}
                  onChange={(e) => setPrice(numberWithDot(e.target?.value))}
                />
              </FormGroup>
              <FormGroup>
                <Label for="comission">Comisión %</Label>
                <Input
                  type="text"
                  placeholder="Comision %..."
                  disabled={loading}
                  value={comission }
                  onChange={(e) => setComission(e.target?.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="iva">IVA</Label>
                <Input
                  type="text"
                  placeholder="IVA..."
                  disabled={loading}
                  value={iva}
                  onChange={(e) => setIva(e.target?.value)}
                />
              </FormGroup>
            </Col>
            <Col lg="4"></Col>
          </Row>

          <FormGroup>
            <Label for="name">Observaciones</Label>
            <Input
              type="textarea"
              placeholder="Observaciones..."
              disabled={loading}
              onChange={(e) => setObs(e.target?.value)}
              value={obs}
            />
          </FormGroup>
          <Button disabled={loading}>Guardar</Button>
        </Form>
      </Container>
    </>
  );
}
export default CreateQuotation;
